<template>
  <div>
    <article class="container">
      <h2 class="article-heading">
        Privacy Policy<time
          class="article-heading__published-date"
        >ปรับปรุงล่าสุด: 07 พฤศจิกายน 2566</time>
      </h2>
      <div class="article-content">
        <p>
          Cyber-Police Co., Ltd. (hereinafter referred to as the "Company") hereby
          provide the "Cyber-Check Privacy Policy" (hereinafter referred to as
          the "Policy") to help you understand our policy and procedure for
          collection, use and disclosure of personal data accessed by us from
          users of Cyber-Check Website
          (the"Website") and services or software provided by it (the "Cyber-Check
          Service", including but not limited to, the "Cyber-Check Applications",
          and the related service or software series released by us).
        </p>
        <p>
          Please see the "Company" Terms of Service at
          , so you may find more information about how to manage the data you
          choose to submit to the "Cyber-Check Service".
        </p>
        <p>
          The "Policy" is applicable to the data submitted by users to us via
          the "Cyber-Check Service" and the open information displayed by us on
          the user interface when the user accesses to the "Cyber-Check Service".
        </p>
        <p>
          The "Policy" may be amended from time to time. In the case of any
          material change in the Policy, we will post the new Policy on the
          "Website" or notify you of the same via the "Cyber-Check Applications".
          We suggest that you periodically review the "Policy" to confirm
          whether any changes have been made.
        </p>
        <h2 id="definitions">
          1. Definitions
        </h2>
        <p>
          Unless otherwise stated in the “Policy”, the terms used in the
          “Policy” shall have the meaning as defined in the “Company” Terms of
          Service. The data which the “Company” will ask to provide will
          include some data which would be identified as “Personal Data”.
          “Personal Data” includes your email address, phone number(to verify
          your device through one-time password (OTP), SMS and MMS(if you send
          SMS or MMS via Cyber-Check SMS or MMS functions; MMS function only
          for Users sending MMS, no collection of photos or audio, or
          provision for other purposes), photos(to report bugs or errors to
          Cyber-Check, used solely for resolving User issues, no other uses
          permitted), mobile contact list(to identify the phone numbers of
          your mobile contacts), click and search records within the
          application, crash logs, diagnostics, application performance data
          ,and device ID. Additionally, the “Company” collects purchase
          records(excluding credit card number, CVV numbers) for subscribed
          users. The extent of Cyber-Check Service will be dependent on the
          personal data that you provide. If certain personal data isn't
          provided, the functionality of the Cyber-Check Service may be limited.
        </p>
        <h2
          id="purpose-of-the-company-for-collection-of-data-data-collected-and-usage"
        >
          2. Purpose of “the Company” for Collection of Data, Data Collected
          and Usage
        </h2>
        <h3 id="purpose-of-the-company-for-collection-of-data">
          (1) Purpose of the “Company” For Collection of Data
        </h3>
        <p>
          The “Company” will collect data from the “Cyber-Check User”, “Cyber-Check
          Visitor”, “Third Party Database”, “Search Engine Database” and “User
          Generated Content”. The collected User data from the User, includes,
          within the application: email address, phone number(optional), SMS
          and MMS(if you send SMS or MMS via Cyber-Check SMS or MMS functions),
          photos(to report bugs or errors to Cyber-Check), mobile contact list(to
          identify the phone numbers of your mobile contacts), click and
          search records within the application, crash logs, diagnostics,
          application performance data ,and device ID. Additionally, the
          “Company” collects purchase records(excluding credit card number,
          CVV numbers) for subscribed users; and within the website: numbers
          of users, session statistics, approximate geographic location,
          browser and device information.The collected User data will be
          available to the “Website” and “Cyber-Check Service” agreed by the User
          in the service terms and conditions, in line with the following
          purposes: A. for upgrading efficiency and optimizing or improving
          the “Cyber-Check Service”, and, insofar as it is permitted under the
          service terms and conditions, for any other purposes consistent with
          protection of personal data and privacy. B. for serving as the
          important reference for investigation and shooting of existing
          trouble, improvement on the basic service quality, and future
          research and development of new functions C. for marketing the
          “Company’s” products or services, developing or providing users with
          exclusive personal services, and delivering the advertising contents
          you prefer. D. for satisfying the Company’s other business needs:
          development of new products or services, communication of the
          information or latest news about the Company’s other products,
          internal record keeping, or compliance with laws &amp; regulations
          or government’s decrees.
        </p>
        <h3 id="data-collected-by-the-company-and-usage">
          (2) Data Collected by the “Company” and Usage
        </h3>
        <p>
          The personal data collected by the “Company” includes category A to
          F listed hereinafter, in addition to the access logs for the access
          by the “Registered User” to the “Cyber-Check Service” and data or
          information generated from the related procedures, or the
          “Non-Identifiable Data” related to you read from the “communication
          exchange and equipment labeling” in the communication device you
          use. Though the “Non-Identifiable Data” per se cannot afford to
          identify you, once the same combining other data afford to identify
          you directly or indirectly (e.g. combination with your address and
          postal code), they will constitute a part of your “Personal Data.”
        </p>
        <p>
          <strong>A. “User Data”</strong> “User Data” means the information
          stored in your phone which is transmitted via your operating system
          and browser per access authority upon your prior consent, and
          information you choose to be uploaded to the “Cyber-Check Service” upon
          your consent to the User Experience Optimization of “Cyber-Check
          Service” intended to improve and develop related services, including
          but not limited to your own phone number and search logs or block
          logs, marks or notes about the calls related to or connected with
          you, the GPS site or communication service quality presumed or
          judged by the system independently via your access to the “Cyber-Check
          Service”, and the search log of the keywords you might use in the
          “Cyber-Check Service” and your purchase history in the Service.
        </p>
        <p>
          Meanwhile, in order to identify the Cyber-Check visitors visiting or
          accessing your device, you will have to submit your contact person
          list, favorites list and blocklist, in order to help the “Cyber-Check
          Service” provide feedback to you immediately for search and
          identification of the caller ID.
        </p>
        <p>
          Further, when the “Cyber-Check user” feeds back any problem about the
          program and reflects any related matters via the “Report other
          problem” or other transmission functions in the “Cyber-Check App”, the
          “Cyber-Check Service” would use the personal information and private
          messages submitted by the user here (including but not limited to,
          the device information, call log and messages, et al.).
        </p>
        <p>
          <strong>B. Contact Information.</strong> In order to fully utilize
          the core services of Cyber-Check, you must first agree to share contact
          information such as name and number. This can assist us to activate
          the caller identity finder function and optimize our anti-spam
          services. We will never divulge any of your personal information
          outside of Cyber-Check services. Cyber-Check is liable for the security of
          user data, as a result we utilize double encryption technology: AES,
          a symmetric encryption algorithm on the user end, and SSL encrypted
          sessions during data transfer. All of your provided user information
          will be compiled into anonymous statistical analysis data that are
          not identifiable. We are very stringent with user data and will
          protect your privacy and security at all costs. Cyber-Check will never
          illegally sell or disclose your data to third parties outside of
          Cyber-Check services. Certain permissions are only requested to provide
          a better and more robust product service. You can cancel and refuse
          to continue providing any permissions at any time you wish, but the
          full scope of product services will be limited as a result.
        </p>
        <p>
          <strong>C. Caller ID</strong> “Caller ID” means the contents
          submitted via your choice (including but not limited to the
          information you submit to us voluntarily) and any other information
          you provide to Cyber-Police for the ID of a “Cyber-Check User”. You agree
          that “the Company” may review and read the “Caller ID SMS Format” in
          your phone, in order to provide you with the information about the
          telecommunication operator which provides services to your caller.
          Said data will be displayed on the “Cyber-Check Caller ID” in the form
          of your phone number, including but not limited to, your name, phone
          number and any other contact information you provide. You may also
          choose to provide additional “Personal Data” in your personal
          “Cyber-Check Caller ID Data”.
        </p>
        <p>
          <strong>D. Third Party’s Personal Data</strong> You agree that the
          Company may collect your personal data from a third party. The third
          party includes the Company’s subsidiaries, affiliates or partners.
          When the user apply for or access the “Cyber-Check Service” via an
          account registered through any of the Company’s partners, the
          Company will collect from the partner the data, such as your email
          and mobile phone number, and any other data needed by the Service,
          in order to verify the accuracy of your ID.
        </p>
        <p>
          <strong>E. Non-identifiable Data</strong> You agree, or agree in the
          service terms and conditions, that the “Company” may collect or
          process the data about you but individually does not identify you
          directly, such as your Internet connection, your IP address, your
          location, the equipment you use to access our Website, and other
          information about your usage of the Website (collectively
          "Non-identifiable Data").
        </p>
        <p>
          <strong>F. Technical Data and Automatic Collection of Data</strong>
          When you access the “Website” or interact with some service provided
          by us, or you are provided with or fed back some open information by
          the Company upon your access to the “Cyber-Check Service”, as a
          “Registered User”, “Non-registered User” or “Cyber-Check Visitor”, our
          server will automatically record some data received or transmitted
          upon your access to the “Cyber-Check Service” or due to the “Cyber-Check
          Service”, so that we might collect the service information derived
          from the instruction about your access to or receipt of the
          information via the “Cyber-Check Service”, or kept or recorded upon
          your access to any other items provided by the “Cyber-Check Service”,
          provided that said service information will be collected in the form
          of “unidentifiable data”. By so doing, the “Company” may improve the
          “Cyber-Check Service” and also ensure that the Service may be available
          to you and meet your personal preference. The Company also
          appreciates your support toward the optimization and experience of
          Cyber-Check Service. <br><br>Meanwhile, we might also collect the
          information, including but not limited to, your IP address and
          detailed information about your browser, in the manner unable to
          identify the concerned party, by virtue of the “de-identification”
          technology sufficient to eliminate the additional information able
          to identify the concerned party, and use the cookies stored in your
          browser (small-size text file, which will be stored in your PC when
          you are browsing the website; the Company may read the information
          stored in the cookies to track your behavior on the site when you
          are browsing the website again) in an automated manner, or receive
          the correlative information accessible by the Company due to your
          access to the “Cyber-Check Service,” such as items, types and
          frequencies of the information provided by the “Cyber-Check Service” to
          you. You agree that said correlative information, which cannot
          identify the specific concerned party, should be deemed as the
          “non-identifiable data.” Such correlative information may help us
          study and gather statistics of unidentifiable personal information
          so as to upgrade the “Cyber-Check Service” quality and customize the
          Service (including but not limited to, the advertisements and
          contents related to your preference). <br><br>If you do not
          accept the “Company’s” collection, processing and utilization of
          said correlative information, you may choose to stop accepting the
          program from us via the customer’s service channel, but you might
          not be able to use the “Cyber-Check Service” in whole or in part.
          <br><br>For the purpose of maintenance, we will link the user
          information collected by us to our server or to the central server
          managed by a third party subcontractor, and integrate, including but
          not limited to, the information about user’s IP address, operating
          system, website system, type and edition of database, and the
          information provided by the “Cyber-Check Service” to users, in order to
          input the similar information which might be needed by users as
          value-added reference during their access to the Website.
        </p>
        <h2 id="data-sharing-and-disclosure">
          3.Data Sharing and Disclosure
        </h2>
        <p>
          Any messages or comments and related websites you share in the
          business blog or application of the “Company” may be disclosed to
          the public. Any data you choose to provide in your messages or
          comments will reflect the level of information which you hope the
          other users know about you. Cyber-Police suggests that you should use an
          anonymous name and protect sensitive data. Meanwhile, the “Company”
          also encourages the “Cyber-Check Users” to carefully think about their
          personal data to be disclosed in their messages or comments.
        </p>
        <h3 id="data-compilation-and-non-identifiable-data">
          (1) Data compilation and non-identifiable data
        </h3>
        <p>
          TThe “Company” will combine your “User Data” and “Caller ID” with
          the other Cyber-Check Users’ data into the “anonymous statistical
          analysis information” which cannot afford to identify the concerned
          party, by virtue of the "de-identification” technology. Insofar as
          it is necessary to maintain your information safety, with respect to
          the user data provided by you to the “Cyber-Check Service”, the
          “anonymous statistical analysis data” exclude any “personal data”,
          and all of the “Personal Data” included therein will be separated
          and encrypted, and all related logs will be replaced by random
          numbers. In the Cyber-Check database, the Company is dedicated to
          completing de-identification with a series of long digit codes free
          from identifiable personal characteristics. All of the data about
          “Cyber-Check Visitors” used, searched or accessed by you will be
          identified and logged in said manner, and be analyzed, classified
          and identified based on the data about Cyber-Check users represented by
          a series of long digit codes. Please note that Cyber-Check Service will
          not log, collect or save your call record lest the risk over your
          use of data should be increased or your use of data should be
          affected adversely. <br><br>The "Company" will keep the
          "anonymous statistical analysis data", ”Non-identifiable Data”, and
          the logs between a third party for the purpose of analysis, so that
          the “Company” may continue to upgrade the Cyber-Check Service quality.
          <br><br>Further, if the “anonymous statistical analysis data”
          and “non-identifiable data” involve any personal data that may
          identify you directly (including but not limited to, the mobile
          phone number), the Company will replace the same with codes, or make
          them anonymous or concealed. The encrypted “Personal Data” may be
          recovered through decryption, provided that the Company will, by
          itself or via another person, restore the virtual codes for the
          purpose of service analysis, subject to the registered user’s prior
          consent. Meanwhile, the “Company” will not, and will not be able to,
          share with any third party your “Personal Data” which have not yet
          separated and encrypted for any purposes.
        </p>
        <h3 id="service-provider">
          (2) Service provider
        </h3>
        <p>
          The “Company” might retain a third party service provider to help it
          manage and provide the “Cyber-Check Service”. Therefore, the "Company"
          may share your "Personal Data" with our providers for cloud service,
          customer service, web analytics, app analytics, and OTP(One Time
          Password) system services. Notwithstanding, unless the service
          provider is required to run the “Personal Data” involving the
          specific service you already agree, the “Company” will not permit
          the service provider to access or contact your “Personal Data” when
          the data are not encrypted. The “Company” will demand that the
          service provider should undertake and warrant in writing that it
          will not use the personal data accessed or contacted by them without
          permission or against laws, or for any other purposes. In order to
          improve the “Cyber-Check Service” quality, you agree that the “Company”
          may make your “Personal Data” available to the external technical
          developers who have entered into the non-disclosure agreement with
          the “Company”.
        </p>
        <h3 id="compliance-with-law-and-law-enforcement">
          (3) Compliance with law and law enforcement
        </h3>
        <p>
          In order to comply with laws, protect the property and right of the
          “Company” or a third party, maintain the public interest or any
          individual safety, or prevent any activities potentially against
          laws and ethics, if it is required by laws and it is necessary, the
          “Company” shall be entitled to make any of your data available to
          governments, law-enforcing officials, or any third party permitted
          under laws.
        </p>
        <h3 id="transfer-of-business">
          (4) Transfer of business
        </h3>
        <p>
          In the case of merger, acquisition, reorganization, sale of assets
          or bankruptcy, the “Company” will not preclude its business from
          being sold, assigned, or transferred in any other manners, in whole
          or in part, so that the business information including your
          “Personal Data” and “User Data” or “Caller ID” might be accessed or
          received by a third party that merges with, acquires, or reorganizes
          the “Company”.
        </p>
        <h2 id="personal-data-retention-period">
          4. Personal Data Retention Period
        </h2>
        <p>
          During your access to the “Cyber-Check Service”, in order to provide
          you with the service in line with the quality requirements, or
          comply with the Company’s internal audit/internal control
          requirements or related laws &amp; regulations, we need to retain
          your personal data until 30 calendar days after you request its
          deletion via email or in-app. For avoidance of doubts, if you
          uninstall, or fail to access, the “Cyber-Check APP” for at least 12
          consecutive months, you shall be held ceasing to access the Service
          and the Company will delete the personal data you provided in order
          to access the “Cyber-Check Service,” and, therefore, the preceding
          paragraph shall not apply. If, within one month prior to deletion of
          your personal data, you provide your email to the Company, the
          Company will notify you via the email; otherwise, you agree that the
          Company can delete your personal data directly. If there are any
          other applicable laws and regulations expressly defining the data
          retention period, the same shall govern, and the Company will retain
          the data until the end of the period defined therein.
        </p>
        <h2 id="deletion-of-your-data">
          5. Deletion of your data
        </h2>
        <p>
          The "Company" cares about the protection of data, as well as your
          autonomy over the data. The security of “User Data” (which includes
          your “Personal Data”) submitted by users to us is one of the top
          priorities of the “Company”. If you wish to delete your “User Data”,
          in whole or in part, please contact us via service@Cyber-Police.com ,
          Cyber-Check will process your request and delete relevant data
          according to the Terms of Service within 30 calendar days upon the
          receipt of such request. The user who wishes to delete or hide his
          "Caller ID" shall e-mail to
          (privacy@Cyber-Police.com)[mailto:privacy@Cyber-Police.com] and then follow
          the procedure and file a complaint per instruction. The Company may
          delete your personal data in an electronic form or in carbon copy.
          The personal data stored in an electronic form will not be
          recoverable or stored again, once they are deleted, while the
          personal data stored in a carbon copy will be shredded.
        </p>
        <h2 id="other-mobile-phones">
          6. Other mobile phones
        </h2>
        <p>
          The “Cyber-Check Service” is only applicable to the mobile phone owned
          and used by you per se. The “Company” will send a message to your
          mobile phone to notify you of the password to ensure that you own
          the phone number accessible to the “Cyber-Check Service”.
        </p>
        <h2 id="changes-of-the-policy">
          7. Changes of the Policy
        </h2>
        <p>
          The Policy might be amended from time to time. If it is the case,
          Cyber-Check will post the new policy on Cyber-Check Card Website, or
          notify you of the same via the “Cyber-Check Application Program”. The
          changes will take effective immediately on the effective date
          designated when the new policy is promulgated. Your continuous
          access to the various services provided by the Company and
          application programs for relates services shall constitute your
          agreement to the changes and undertaking to comply with the changes.
          If you disagree to the changes, you shall stop accessing all
          services and related application programs provided by the Company.
          We suggest that you should check up the Policy periodically to
          ensure whether there are any changes of the same.
        </p>
        <h2 id="safety">
          8. Safety
        </h2>
        <p>
          The “Company” cares about the protection of your “Personal Data”. In
          order to protect your data from unauthorized access, Cyber-Police will
          apply the safety standards, hardware and electronic equipment
          required under the local laws applicable in the jurisdiction where
          the “Company” are situated.
        </p>
        <h2 id="cross-border-transmission">
          9. Cross-border Transmission
        </h2>
        <p>
          Your data might be transmitted and stored in any computers located
          in the territories other than the territory, province (state) or
          country where you are located or in any other jurisdictions where no
          privacy protection is applicable availably like where you are
          located, including the data centers in the USA and Japan. The
          collected personal data will be encrypted via the server well
          protected, and then transmitted to the data server via the
          information and communication network immediately. If you are
          located in any country other than Taiwan and choose to provide your
          “Personal Data” to the “Company”, the relevant data will be
          transmitted to Taiwan directly and be processed in Taiwan. If you
          agree to the “Company” “Privacy Policy” and submit the data, you
          agree to such transmission.
        </p>
        <h2 id="link-to-other-sites">
          10. Link to Other Sites
        </h2>
        <p>
          The “Website” includes the links to other sites. The “Company”
          access to some website or display of some advertisement does not
          represent any affiliation with the third party, or acknowledgement,
          license or representation between us and the third party. The
          “Company” does not have the control over the third party’s website.
          Therefore, when you visit other sites via the “Website” or “Cyber-Check
          Service”, the “Company” hopes you to read their privacy policies or
          statements carefully.
        </p>
        <h2 id="fake-sites">
          11. Fake sites
        </h2>
        <p>
          The "Company" cares about ID theft and “Fake Site”. The “Company”
          takes the protection of your “Personal Data” as the first priority.
          The “Company” will never ask you for your credit card information,
          account and ID, password or national ID No. or equivalent
          identifiable codes via an unsafe or unknown email or phone number,
          now or in the future. Notwithstanding, should you have any questions
          about our Privacy Policy, please e-mail to:
          <!-- <a href="emailto:privacy@Cyber-Police.com">privacy@Cyber-Police.com</a> -->
        </p>
        <h2 id="child-policy">
          12. Child Policy
        </h2>
        <p>
          Cyber-Check will not collect the personal information of any children
          less than 14 years old intentionally. If the parents or guardians of
          any children find that the children have already provided their
          personal information to Cyber-Check without approval from them, they
          shall contact us via
          (service@Cyber-Police.com)[mailto:service@Cyber-Police.com]. If Cyber-Check
          finds any personal information provided by children less than 14
          years old, Cyber-Check will delete all related documents, but this does
          not represent that Cyber-Check is able to or obligated to prevent or
          delete the personal information provided by children less than 14
          years old.
        </p>
        <h2 id="supreme-administrator-of-personal-data">
          13. Supreme Administrator of Personal Data
        </h2>
        <p>
          The Company’s Chief Privacy Officer serves as the supreme
          administrator in charge of personal data protection. Should you have
          any questions or comments on the “Policy,” our contact information
          is specified as following:<br>Address: 23-1F, No. 102, Sec. 2,
          Roosevelt Rd., Zhongzhen Dist., Taipei City, Taiwan (R.O.C.)<br>
          Email: , or
        </p>
        <h2 id="previous-policy-versions">
          14. Previous Policy Versions
        </h2>
        <p>
          Issue Date: 2023/05/07<br>
          Issue Date: 2022/05/14<br>
          Issue Date: 2020/07/12<br>
        </p>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ArticleTerm',
  components: {},
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div>
    <navbar />

    <div class="main-container">
      <article-term />
    </div>
  </div>
</template>

<script>
import navbar from './components/navbars.vue'
import ArticleTerm from './components/article_term.vue'

export default {
  components: { navbar, ArticleTerm },
}
</script>

<style lang="scss" scoped>
</style>
